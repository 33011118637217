import { useTranslation } from "react-i18next";
import { RiPhoneFill, RiMailFill, RiTimeFill } from "react-icons/ri";

function Footer() {
  const { t } = useTranslation();
  const today = new Date();

  return (
    <>
      <iframe
        src="https://frame.mapy.cz/s/katucazuzu"
        width="900"
        height="500"
        frameBorder="0"
        title="map"
      ></iframe>

      <div className="footer__info">
        <img src="./images/logo.svg" width="140" alt="logo" />

        <ul className="info__list">
          <li>Restaurace Dolní dvůr - Šilheřovice</li>
          <li>Dolní 153</li>
          <li>747 15 Šilheřovice</li>
          <li>
            <RiPhoneFill className="info__list__icon" /> +420 728 322 383
          </li>
          <li>
            <RiMailFill className="info__list__icon" />
            info@dolnidvursilherovice.cz
          </li>
        </ul>

        <ul className="info__list">
          <li>
            <RiTimeFill className="info__list__icon" /> {t("footer.open")}
          </li>
          <li>
            <div className="item">
              {t("footer.tue")} <em>17 - 21</em>
            </div>
          </li>
          <li>
            <div className="item">
              {t("footer.wed")} <em>17 - 21</em>
            </div>
          </li>
          <li>
            <div className="item">
              {t("footer.thr")} <em>12 - 21</em>
            </div>
          </li>
          <li>
            <div className="item">
              {t("footer.fri")} <em>12 - 21</em>
            </div>
          </li>
          <li>
            <div className="item">
              {t("footer.sat")} <em>12 - 22</em>
            </div>
          </li>
          <li>
            <div className="item">
              {t("footer.sun")} <em>12 - 22</em>
            </div>
          </li>
        </ul>

        <p>&copy;{today.getFullYear()} Dolní Dvůr Šilheřovice</p>
      </div>
    </>
  );
}

export default Footer;
