import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";

function Gallery() {
  const { t } = useTranslation();

  function getStaticImages() {
    let fotos = [];

    for (let i = 1; i < 29; i++) {
      fotos.push({
        original: `../images/gallery/${i}.jpg`,
        thumbnail: `../images/gallery/${i}.jpg`,
        originalAlt: `../images/gallery/${i}.jpg`,
      });
    }

    return fotos;
  }

  const foto = getStaticImages();

  console.log(foto);

  return (
    <>
      <h2 className="gallery__title">{t("gallery.title")} </h2>

      <ImageGallery items={foto} autoPlay={true} />
    </>
  );
}

export default Gallery;
