import React from "react";
import { useTranslation } from "react-i18next";

function About() {
    const { t } = useTranslation();

  return (
    <>
      <picture className="about__image">
        <source srcSet="./images/intro_img.png" media="(min-width: 769px)" />
        <img src="./images/wide3.png" alt="" />
      </picture>
      <div className="about__text">
        <p>{t("home.text")}</p>
      </div>
    </>
  );
}

export default About;
