import "./App.scss";
import Navigation from "./components/Navigation";
import About from "./components/About";
import Gallery from "./components/Gallery";
import MailForm from "./components/MaiForm";
import Footer from "./components/Footer";
import ScrollTo from "./components/ScrollTo";
import Slider from "./components/Slider";
//import Menu from "./components/Menu";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Navigation />

      <Slider />

      <div className="about" id="about">
        <About />
      </div>

      <div className="gallery" id="gallery">
        <Gallery />
      </div>

      {/*   <div className="menu" id="menu">
        <Menu />
      </div> */}

      <div className="contact" id="contact">
        <MailForm />
      </div>

      <div className="footer" id="footer">
        <Footer />
      </div>

      <ToastContainer />
      <ScrollTo />
    </div>
  );
}

export default App;
