import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function MailForm(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  

  const { register, handleSubmit, reset, errors } = useForm();

  function onSubmit(e) {
    //e.preventDefault();

    emailjs
      .sendForm(
        "service_2uzj26z",
        "template_yswdk9w",
        //e.target,
        "#contact-form",
        "user_Z3WD8X3vooBIGbYG3ygH5"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    toast.success(`${t("contact.message_sent")}`, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

    reset();
  }

  const { t } = useTranslation();

  return (
    <>
      <h2 className="contact__title">{t("contact.title")}</h2>

      <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
        <div className="contact__input__wrap">
          <input
            name="name"
            type="text"
            placeholder={t("contact.name")}
            className="border1"
            onChange={(e) => setName(e.currentTarget.value)}
            ref={register({ required: true, minLength: 3, maxLength: 20 })}
          />
          <span className="bb"></span>
        </div>
        {errors.name && errors.name.type === "required" && (
          <div className="validation-error">{t("errors.required")}</div>
        )}
        {errors.name && errors.name.type === "minLength" && (
          <div className="validation-error">{t("errors.minlength")}</div>
        )}
        {errors.name && errors.name.type === "maxLength" && (
          <div className="validation-error">{t("errors.maxlength")}</div>
        )}

        <div className="contact__input__wrap">
          <input
            name="email"
            type="email"
            placeholder={t("contact.email")}
            className="border1"
            onChange={(e) => setEmail(e.currentTarget.value)}
            ref={register({
              required: true,
              pattern:
                /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i,
            })}
          />
          <span className="bb"></span>
        </div>
        {errors.email && errors.email.type === "required" && (
          <div className="validation-error">{t("errors.required")}</div>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <div className="validation-error">{t("errors.pattern")}</div>
        )}

        <div className="contact__input__wrap">
          <textarea
            className="border1"
            name="message"
            placeholder={t("contact.message")}
            onChange={(e) => setText(e.currentTarget.value)}
            ref={register({ required: true })}
          ></textarea>
          <span className="bb"></span>
        </div>
        {errors.message && errors.message.type === "required" && (
          <div className="validation-error">{t("errors.required")}</div>
        )}

        <div className="contact__button">
          <button className="contact__button__send" type="submit">
            {t("contact.send")}
          </button>
        </div>
      </form>
    </>
  );
}

export default MailForm;
