import React from "react";

import { Link } from "react-scroll";

function Slider() {
  return (
    <div className="slider" id="top">
      <div
        className="slider__slide"
        style={{
          backgroundImage: ` url(../images/1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Link to="about" smooth={true} offset={-200}>
        <img src="./images/scrolldown.png" alt="down" className="scroll" />
      </Link>
    </div>
  );
}

export default Slider;
