import React, { useState } from "react";
import { RiArrowUpSLine } from "react-icons/ri";
import { Link } from "react-scroll";

function ScrollTo() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <Link
      to="top"
      smooth={true}
      className="scrollTop"
      style={{ display: showScroll ? "flex" : "none" }}
    >
      <RiArrowUpSLine />
    </Link>
  );
}

export default ScrollTo;
