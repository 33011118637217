import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";

import Loader from "./components/Loader";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
