import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

function Navigation({ scrollGallery, scrollAbout, scrollContact }) {
  const { t, i18n } = useTranslation();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavigation = () => {
    setIsNavOpen(!isNavOpen);
  };

  const languageSwitch = (language) => {
    i18n.changeLanguage(language);
  };

  const [isCzech, setIsCzech] = useState(true);

  const toggleLang = () => {
    setIsCzech(!isCzech);
  };

  return (
    <div className="navigation-container">
      <div className="navigation">
        <div
          className={`navigation__toggle ${isNavOpen ? "open" : ""}`}
          onClick={toggleNavigation}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="navigation__lang">
          <div onClick={toggleLang}>
            {isCzech ? (
              <button onClick={() => languageSwitch("pl")}>PL</button>
            ) : (
              <button onClick={() => languageSwitch("cs")}>CS</button>
            )}
          </div>
        </div>

        <div className={`navigation__menu ${isNavOpen ? "collapse" : ""}`}>
          <Link
            to="about"
            smooth={true}
            offset={-200}
            className="navigation__menu__item"
            onClick={toggleNavigation}
          >
            <button>{t("links.about")}</button>
          </Link>

          <Link
            to="gallery"
            smooth={true}
            offset={-200}
            className="navigation__menu__item"
            onClick={toggleNavigation}
          >
            <button>{t("links.gallery")}</button>
          </Link>

{/*           <Link
            to="menu"
            smooth={true}
            offset={-200}
            className="navigation__menu__item"
            onClick={toggleNavigation}
          >
            <button>{t("links.menu")}</button>
          </Link> */}

          <Link
            to="contact"
            smooth={true}
            offset={-200}
            className="navigation__menu__item"
            onClick={toggleNavigation}
          >
            <button>{t("links.contact")}</button>
          </Link>

          <div className="navigation__menu__item">
            <div onClick={toggleLang}>
              {isCzech ? (
                <button onClick={() => languageSwitch("pl")}>PL</button>
              ) : (
                <button onClick={() => languageSwitch("cs")}>CS</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
